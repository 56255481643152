import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import Swal from 'sweetalert2';
import API_ASISTENCIA from '../../componentes/config/apisAsistencia_Variables';
// Importa el archivo SVG como fuente para el ícono
import serenosIcon from '../../assets/markeractivo.gif';

/* Configuración del icono personalizado */
const customIconCamaras = new L.Icon({
  iconUrl: serenosIcon,  // Usamos el archivo serenos.svg
  iconSize: [32, 32],     // Tamaño del ícono
  iconAnchor: [16, 32],   // El punto de anclaje del ícono
  popupAnchor: [0, -32],  // Donde se posiciona el Popup
 
});

const PersonalRonda = ({ fecha }) => {
  const [selectedDate, setSelectedDate] = useState(fecha || new Date().toISOString().split('T')[0]);
  const [horaInicio, setHoraInicio] = useState('');
  const [horaFin, setHoraFin] = useState('');
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleHoraInicioChange = (e) => setHoraInicio(e.target.value);
  const handleHoraFinChange = (e) => setHoraFin(e.target.value);

  const fetchReportData = async () => {
    setIsLoading(true);
    setReportData(null);
    try {
      const url = API_ASISTENCIA.RondasPorFecha(selectedDate, horaInicio, horaFin);
      console.log('URL generada:', url); // Para depuración
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${btoa('ACASUSOLF65:47476065')}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Verificamos si la respuesta es exitosa
      const data = await response.json();
      console.log('Datos recibidos:', data); // Para depuración
  
      if (response.ok) {
        // Si la API devuelve el mensaje de no datos
        if (data.message && data.message.includes('No se encontraron rondas')) {
          // Mostrar el mensaje de la API
          Swal.fire({
            title: 'Sin rondas',
            text: data.message, // Mostrar el mensaje de la API
            icon: 'warning',
          });
        } else if (data.rondas && data.rondas.length > 0) {
          setReportData(data);  // Si hay rondas, los almacenamos
        } else {
          // Caso en que no hay rondas pero no hay mensaje específico
          Swal.fire({
            title: 'Sin rondas',
            text: 'No hay rondas disponibles para los parámetros seleccionados.',
            icon: 'warning',
          });
        }
      } else {
        // Si la respuesta tiene error, mostramos el mensaje de error de la API
        Swal.fire({
          title: 'RONDAS NO DISPONIBLES',
          text: data.message || 'Error desconocido al obtener los datos.',
          icon: 'warning',
        });
      }
    } catch (error) {
      // En caso de error en la solicitud, mostramos el mensaje de error real
      console.error('Error en la solicitud:', error);
      Swal.fire({
        title: 'Error',
        text: `No se pudo obtener los datos. Detalles: ${error.message}`,
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchReportData();
  }, [selectedDate, horaInicio, horaFin]);

  const handleMarkerClick = (marker) => {
    const defaultImage = require('../../assets/perfil.png'); // Imagen por defecto
  
    const photoContent = marker.foto !== 'SIN REGISTRO DE FOTO' ? 
      `<img src="${marker.foto}" alt="Foto" style="max-width: 100%; max-height: 200px; object-fit: cover;" />` :
      `<img src="${defaultImage}" alt="Foto por defecto" style="max-width: 100%; max-height: 200px; object-fit: cover;" />
       <p>Sin registro de foto</p>`;
  
    // Mostrar SweetAlert2
    Swal.fire({
      title: 'DETALLES DE LA UBICACIÓN',
      html: `
        <div class="row">
          <!-- Columna izquierda -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="row mb-2">
              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <label for="nombre" class="col-form-label text-left" style="font-size: 14px;"><strong>Nombre:</strong></label>
                <input type="text" class="form-control" id="nombre" value="${marker.nombre_completo || 'Desconocido'}" disabled style="font-size: 14px;" />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <label for="documento" class="col-form-label text-left" style="font-size: 14px;"><strong>Documento:</strong></label>
                <input type="text" class="form-control" id="documento" value="${marker.SERE_P_chDNI}" disabled style="font-size: 14px;" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <label for="funcion" class="col-form-label text-left" style="font-size: 14px;"><strong>Función:</strong></label>
                <input type="text" class="form-control" id="funcion" value="${marker.SERE_chFUNCION || 'Desconocido'}" disabled style="font-size: 14px;" />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <label for="modalidad" class="col-form-label text-left" style="font-size: 14px;"><strong>Modalidad:</strong></label>
                <input type="text" class="form-control" id="modalidad" value="${marker.modalidad || 'Desconocido'}" disabled style="font-size: 14px;" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <label for="latitud" class="col-form-label text-left" style="font-size: 14px;"><strong>Latitud:</strong></label>
                <input type="text" class="form-control" id="latitud" value="${marker.asis_in_lat}" disabled style="font-size: 14px;" />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <label for="longitud" class="col-form-label text-left" style="font-size: 14px;"><strong>Longitud:</strong></label>
                <input type="text" class="form-control" id="longitud" value="${marker.asis_in_lon}" disabled style="font-size: 14px;" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <label for="fecha" class="col-form-label text-left" style="font-size: 14px;"><strong>Fecha:</strong></label>
                <input type="text" class="form-control" id="fecha" value="${marker.ASIS_dtFECHOR}" disabled style="font-size: 14px;" />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <label for="hora" class="col-form-label text-left" style="font-size: 14px;"><strong>Hora:</strong></label>
                <input type="text" class="form-control" id="hora" value="${marker.ASIS_chHOR}" disabled style="font-size: 14px;" />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <label for="ronda" class="col-form-label text-left" style="font-size: 14px;"><strong>Ronda:</strong></label>
                <input type="text" class="form-control" id="ronda" value="${marker.ASIS_chEntSal}" disabled style="font-size: 14px;" />
              </div>
            </div>
          </div>
          <!-- Columna derecha -->
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div style="display: flex; justify-content: center; align-items: center; text-align: center; margin-top: 15px;">
              ${photoContent}
            </div>
            <label for="direccion" class="col-form-label text-left" style="font-size: 14px;"><strong>Dirección:</strong></label>
            <textarea class="form-control" id="direccion" rows="4" disabled style="font-size: 14px; width: 100%;">${marker.asis_ch_mapdir || 'SIN REGISTRO'}</textarea>
          </div>
        </div>
      `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Cerrar',
      footer: `
        <button id="recorrido-btn" class="btn btn-success" style="margin-right: 10px;">Recorrido</button>
        <button id="alerta-btn" class="btn btn-danger">Alerta</button>
      `,
      width: '80%',
      padding: '2em',
      customClass: {
        content: 'swal-content'
      },
      didOpen: () => {
        // Manejo del botón "Recorrido"
        document.getElementById('recorrido-btn').addEventListener('click', () => {
          Swal.close(); // Cierra el SweetAlert
          // Abre el modal
          console.log('Abrir modal de recorrido');
          // Aquí puedes disparar la lógica para abrir tu modal
        });
  
        // Manejo del botón "Alerta"
        document.getElementById('alerta-btn').addEventListener('click', () => {
          Swal.close(); // Cierra el SweetAlert
          Swal.fire({
            icon: 'warning',
            title: '¡Alerta activada!',
            text: 'Se ha enviado una alerta.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#d33',
          });
        });
      }
    });
  };
  

  return (
    <div style={{ width: '100%' }}>


      <div className="row mx-2 my-2">

            {/* Filtros */}
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">

              <div className="row">
                <div className="col-12 mb-2">
                  <a href="/principal" className="btn btn-sm btn-primary w-100">
                    <i className="fa-solid fa-home"></i> Principal
                  </a>
                </div>

                <div className='card m-2'>
                  <div className='card-header'>
                    <h6 className="card-title text-bold">APLICAR FILTROS</h6>
                  </div>
                  <div className='card-body'>
                    <div className="mb-3">
                      <label htmlFor="dateFilter" className="form-label mb-0">Fecha:</label>
                      <input
                        type="date"
                        id="dateFilter"
                        className="form-control form-control-sm"
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{ maxWidth: '100%' }}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="horaInicio" className="form-label mb-0">Hora Inicio:</label>
                      <input
                        type="time"
                        id="horaInicio"
                        className="form-control form-control-sm"
                        value={horaInicio}
                        onChange={handleHoraInicioChange}
                        style={{ maxWidth: '100%' }}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="horaFin" className="form-label mb-0">Hora Fin:</label>
                      <input
                        type="time"
                        id="horaFin"
                        className="form-control form-control-sm"
                        value={horaFin}
                        onChange={handleHoraFinChange}
                        style={{ maxWidth: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>


            {/* mapa */}
            <div className="col-sm-10 col-md-10 col-lg-10 col-xs-12" style={{ maxWidth: "100%" }}>
              <h6 className='text-bold mt-2 mr-3'>
                UBICACIÓN DE PERSONAL EN CAMPO
              </h6>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <MapContainer 
                      center={{ lat: -12.068773, lng: -77.02908 }}
                      zoom={16}
                      style={{ width: "100%", height: "85vh" }} >
                      
                      <TileLayer
                        attribution={`MUNICIPALIDAD DISTRITAL DE LA VICTORIA VERSION 1.0 ${new Date().getFullYear()}`}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      {reportData && reportData.rondas && reportData.rondas.length > 0 ? (
                        reportData.rondas
                          .filter((ronda) => ronda.asis_in_lat && ronda.asis_in_lon)
                          .map((ronda, index) => (
                            <Marker
                              key={index}
                              position={{ lat: ronda.asis_in_lat, lng: ronda.asis_in_lon }}
                              icon={customIconCamaras}
                              eventHandlers={{
                                click: () => handleMarkerClick(ronda),
                              }}
                            >
                              <Popup>
                                <div>
                                  <h6>{ronda.nombre_completo}</h6>
                                  <p>{ronda.asis_ch_mapdir || 'No disponible'}</p>
                                </div>
                              </Popup>
                            </Marker>
                          ))
                      ) : (
                        <div>No hay datos de rondas.</div>
                      )}

                    </MapContainer>
                  </div>
                </div>
            </div>

      </div>


    </div>
  );
};

export default PersonalRonda;
