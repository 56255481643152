import {Navigate, Route, Routes} from 'react-router-dom'
import { RoutesPrivate } from './ProtectedRoute'
import PersonalSeguridad from '../Pages/Personalseguridad/PersonalSeguridad'
import MantenimientoSeguridad from '../Pages/mantenimiento/personal'
import PersonalRonda from '../Pages/PersonalRondas/personalRondas'
import GestionPagos from '../Pages/GestionPagos/GestionPagos'
import Principal from '../Pages/Principal/Principal'




const PrivateRoutes = () => {

  return (
    <Routes>
        <Route path='/*' element={<Navigate to={RoutesPrivate.DASHBOARD} />} />
        <Route path={RoutesPrivate.DASHBOARD} element={<Principal />} />
        <Route path={RoutesPrivate.PERSONALSEGURIDAD} element={<PersonalSeguridad />} />
        <Route path={RoutesPrivate.MANTENIMIENTOSEGURIDAD} element={<MantenimientoSeguridad />} />
        <Route path={RoutesPrivate.PERSONALRONDA} element={<PersonalRonda />} />
        <Route path={RoutesPrivate.GESTIONPAGOS} element={<GestionPagos />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}
export {PrivateRoutes}
