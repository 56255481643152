/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Slider from "react-slick";
import Select from "react-select";
import Swal from "sweetalert2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../CSS/formulario.css';
import API_PERSONALSEGURIDAD from "../../componentes/config/apisPersonalS_Variables";

function FormUsuario({ show, onHide, initialData, onSubmitSuccess }) {
  const [funciones, setFunciones] = useState([]);
  const [selectedFuncion, setSelectedFuncion] = useState("");
  const initialFormData = {
    SERE_chAPEPAT: "",
    SERE_chAPEMAT: "",
    SERE_chNOM: "",
    SERE_chTIPODOC: "",
    SERE_P_chDNI: "",
    SERE_chCLAVE: "",
    SERE_chCargo: selectedFuncion,
    sere_dtfecnac: "",
    SERE_chTELEF: "",
    SERE_chEMAIL: "",
    SERE_chSEXO: null,
    SERE_chRUC: "",
    SEGU_inFLAELI: 1,
    SERE_chDIR: "",
    SERE_chCONTRATO: "",
    SERE_chLICCON: "",
    SERE_chTALPOL: "",
    SERE_chTALPAN: "",
    SERE_chTALCAL: "",
    SERE_reTALLA: "",
    SERE_rePESO: "",
    SERE_chTIPSAN: "",
    SERE_foSUE: null,
    //SERE_chFECALT: "",
    //SERE_chFECBAJ: "",
    AFIL_P_inID: "",
    //SERE_chNCCI: "",
    //SERE_chCTABAN: "",
    //SERE_chNOMBAN: "Scotiabank",
    //SERE_chTIPCTA: "Cuenta de Ahorros",
    //SERE_chCTAOPC: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const formatDateForDisplay = (dateString) => {
    if (dateString.length !== 8) return dateString; // Validación rápida para asegurar el formato esperado

    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (initialData) {
      setFormData({
        SERE_chAPEPAT: initialData.SERE_chAPEPAT || "",
        SERE_chAPEMAT: initialData.SERE_chAPEMAT || "",
        SERE_chNOM: initialData.SERE_chNOM || "",
        SERE_P_chDNI: initialData.SERE_P_chDNI || "",
        SERE_chTIPODOC: initialData.SERE_chTIPODOC || "",
        SERE_chCLAVE: initialData.SERE_chCLAVE || "",
        SERE_chRol: initialData.SERE_chCargo || null,
        sere_dtfecnac: initialData.sere_dtfecnac || null,
        SERE_chTELEF: initialData.SERE_chTELEF || "",
        SERE_chEMAIL: initialData.SERE_chEMAIL || "",
        SERE_chSEXO: initialData.SERE_chSEXO || null,
        SERE_chRUC: initialData.SERE_chRUC || "",
        SEGU_inFLAELI: initialData.SEGU_inFLAELI || 1,
        SERE_chDIR: initialData.SERE_chDIR || "",
        SERE_chCONTRATO: initialData.SERE_chCONTRATO || "",
        SERE_chLICCON: initialData.SERE_chLICCON || "",
        SERE_chTALPOL: initialData.SERE_chTALPOL || "",
        SERE_chTALPAN: initialData.SERE_chTALPAN || "",
        SERE_chTALCAL: initialData.SERE_chTALCAL || '',
        SERE_reTALLA: initialData.SERE_reTALLA || null,
        SERE_rePESO: initialData.SERE_rePESO || null,
        SERE_chTIPSAN: initialData.SERE_chTIPSAN || "",
        SERE_foSUE: initialData.SERE_foSUE || null,
        //SERE_chFECALT: initialData.SERE_chFECALT || "",
        //SERE_chFECBAJ: initialData.SERE_chFECBAJ || "",
        AFIL_P_inID: initialData.AFIL_P_inID || null,
        //SERE_chNCCI: initialData.SERE_chNCCI || "",
        //SERE_chCTABAN: initialData.SERE_chCTABAN || "",
        //SERE_chNOMBAN: initialData.SERE_chNOMBAN || "Scotiabank",
        //SERE_chTIPCTA: initialData.SERE_chTIPCTA || "Cuenta de Ahorros",
        //SERE_chCTAOPC: initialData.SERE_chCTAOPC || "",
      },
      );
    } else {
      setFormData(initialFormData);
    }
  }, [initialData]);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'SERE_reTALLA' || name === 'SERE_rePESO' || name === 'SERE_chTALCAL' || name === 'AFIL_P_inID' || name === 'SERE_foSUE' || name === 'SERE_chCTABAN' || name === 'SERE_chNCCI' || name === 'SERE_chCTAOPC') {
      if (!/^\d*\.?\d*$/.test(value) && value !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Valór Invalido',
          text: 'Solo se permite valores numéricos'
        });
      }
      else {
        setFormData({ ...formData, [name]: value });

      }

    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const formatDate = (date) => {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${day}${month}${year}`;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dni = initialData.SERE_P_chDNI
      const apiUrl = initialData
        ? API_PERSONALSEGURIDAD['putUsuario'](dni)
        : API_PERSONALSEGURIDAD['postUsuario'];

      const method = initialData ? "PUT" : "POST";
      console.log(initialData);
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          SERE_chFECALT: formData.SERE_chFECALT
            ? formatDate(new Date(formData.SERE_chFECALT))
            : null,
          SERE_chFECBAJ: formData.SERE_chFECBAJ
            ? formatDate(new Date(formData.SERE_chFECBAJ))
            : null,
        }),
      });

      if (response.ok) {
        console.log(initialData ? "Actualización exitosa" : "Registro exitoso");

        const successMessage = initialData
          ? "El Usuario se actualizó correctamente."
          : "El Usuario se registró correctamente.";

        Swal.fire({
          icon: "success",
          title: initialData
            ? "Actualización de Usuario"
            : "Registro de Usuario",
          text: successMessage,
        }).then(() => {
          clearForm();
          onHide();
          if (onSubmitSuccess) {
            onSubmitSuccess();
          }
        });
      } else {
        console.error(
          initialData ? "Error en la actualización" : "Error en el registro"
        );
      }
    } catch (error) {
      console.error("Error de red:", error);
      const errorMessage = initialData
        ? "No se pudo actualizar el usuario."
        : "El usuario ya ha sido registrado anteriormente.";

      Swal.fire({
        icon: "error",
        title: initialData
          ? "Error al actualizar usuario"
          : "Error al registrar usuario",
        text: errorMessage,
      });
    }
  };
  useEffect(() => {
    const fetchFunciones = async () => {
      try {
        const response = await axios.get(
          API_PERSONALSEGURIDAD['funciones']
        );
        setFunciones(response.data);
      } catch (error) {
        console.error("Error al obtener las funciones:", error.message);
      }
    };

    fetchFunciones();
  }, []);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <div style={{ color: "red", fontSize: "24px", marginLeft: "-20px" }}>
        &lt;
      </div>
    ),
    nextArrow: (
      <div style={{ color: "green", fontSize: "24px", marginRight: "-20px" }}>
        &gt;
      </div>
    ),
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '31px', // Altura mínima para que coincida con form-control-sm
      height: '31px',
      fontSize: '0.875rem', // Tamaño de fuente para form-control-sm
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '31px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '31px',
    }),
  };

  return (
    <Modal show={show} onHide={onHide} size={"xl"} centered>
      <Modal.Header
        closeButton
        className={initialData ? "bg-warning" : "bg-success"}
      >
        <Modal.Title style={{ color: "#fff", "text-align": "center" }}>
          <h2>{initialData ? "Actualizar Usuario " : "Crear Usuario"}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Slider {...settings} style={{ margin: "20px" }}>
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">

                <div className="row">

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                    <div className="form-group">
                      <label  htmlFor="SERE_chNOM" className="label">NOMBRE:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chNOM"
                          className="input form-control form-control-sm"
                          value={formData.SERE_chNOM}
                          onChange={handleChange}
                          placeholder="Ingrese sus nombres"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-user" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="SERE_chAPEPAT"  className="label">APELLIDO PATERNO:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chAPEPAT"
                          className="input form-control form-control-sm"
                          value={formData.SERE_chAPEPAT}
                          onChange={handleChange}
                          placeholder="Ingrese su apellido Paterno"
                        />
                        <span class="input-group-text bg-secondary"><i class="fas fa-user-plus" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="SERE_chAPEMAT" className="label">APELLIDO MATERNO:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chAPEMAT"
                          className="input form-control form-control-sm"
                          value={formData.SERE_chAPEMAT}
                          onChange={handleChange}
                          placeholder="Ingrese su apellido materno"
                        />
                        <span class="input-group-text bg-secondary"><i class="fas fa-user-minus" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="SERE_chSEXO" className="label">SEXO:</label>
                      <div class="input-group">
                        <select
                          className="input form-select form-select-sm"
                          name="SERE_chSEXO"
                          value={formData.SERE_chSEXO}
                          onChange={handleChange}
                        >
                          <option disabled selected>
                            Selecciona el sexo
                          </option>
                          <option value="M">Masculino</option>
                          <option value="F">Femenimo</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  
                </div>


                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="" className="label">CARGO:</label>
                      <Select
                        styles={customStyles}
                        className="input w-100 SelectFuncionRU"
                        options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                        onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona cargo de contrato"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="" className="label">FUNCIÓN (Operativa):</label>
                      <Select
                        styles={customStyles}
                        className="input w-100 SelectFuncionRU"
                        options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                        onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona una Función"
                      />
                    </div>
                  </div>


                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="row">
                      <div className="col-xs-6 col-sm-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="SERE_chTIPODOC" className="label">
                            TIPO DE DOCUMENTO
                          </label>
                          <select
                            class="INPUT form-select form-select-sm"
                            name="SERE_chTIPODOC"
                            value={formData.SERE_chTIPODOC}
                            onChange={handleChange}
                          >
                            <option value="DNI">DNI</option>
                            <option value="CARNET DE EXTRANJERIA">CARNET DE EXTRANJERIA</option>
                            <option value="PASAPORTE">PASAPORTE</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="">Documento:</label>
                          <div class="input-group">
                            <input type="text"
                              name="SERE_P_chDNI"
                              className="form-control"
                              value={formData.SERE_P_chDNI}
                              onChange={handleChange}
                              placeholder="Ingrese su N° de Documento"
                            />
                            <span class="input-group-text bg-warning"><i class="fa-solid fa-id-card"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Ruc:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chRUC"
                          className="form-control"
                          value={formData.SERE_chRUC}
                          onChange={handleChange}
                          placeholder="Ingrese su RUC"
                        />
                        <span class="input-group-text bg-warning"><i class="fa-solid fa-id-card-clip"></i></span>
                      </div>
                    </div>
                  </div>


                </div>

                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Email:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chEMAIL"
                          className="form-control"
                          value={formData.SERE_chEMAIL}
                          onChange={handleChange}
                          placeholder="Ingrese su Email"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-envelope"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Dirección:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chDIR"
                          className="form-control"
                          value={formData.SERE_chDIR}
                          onChange={handleChange}
                          style={{ fontSize: '10px' }}
                          placeholder="Ingrese su dirección"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-map-location-dot"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Tipo de Contrato:</label>
                      <input
                        type="text"
                        name="SERE_chCONTRATO"
                        className="form-control"
                        value={formData.SERE_chCONTRATO}
                        onChange={handleChange}
                        placeholder="Ingrese el tipo de contrato"
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ "margin-top": "10px " }}>

                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Nacimiento:</label>
                      <input
                        type="date"
                        name="sere_dtfecnac"
                        className="form-control"
                        value={formData.sere_dtfecnac}
                        onChange={handleChange}

                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="SERE_chTELEF" className="label">TELÉFONO:</label>
                      <div class="input-group">
                        <input type="number"
                          name="SERE_chTELEF"
                          className="input form-control form-control-sm"
                          value={formData.SERE_chTELEF}
                          onChange={handleChange}
                          placeholder="Ingrese su Teléfono"
                        />
                        <span class="input-group-text bg-secondary"><i class="fas fa-phone" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                </div>
                {initialData ? (
                  ""
                ) : (
                  <div
                    className="col-xs-8 col-sm-8 col-md-8"
                    style={{ "margin-top": "10px ", margin: "auto" }}
                  >
                    <div className="form-group">
                      <label htmlFor="">Clave:</label>
                      <div class="input-group">
                        <input type="password"
                          name="SERE_chCLAVE"
                          className="form-control"
                          value={formData.SERE_chCLAVE}
                          onChange={handleChange}
                          placeholder="Ingrese su Clave"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-key"></i></span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div>
            <div className="row">
              <h2 style={{ textAlign: "center" }}>Datos Laborales</h2>

              <div
                className="col-xs-12 col-sm-12 col-md-12"
                style={{ padding: "15px" }}
              >
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group"></div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">N° AFP:</label>
                      <div class="input-group">
                        <input type="text"
                          name="AFIL_P_inID"
                          className="form-control"
                          value={formData.AFIL_P_inID}
                          onChange={handleChange}
                          placeholder="Ingrese su N° de AFP"
                        />
                        <span class="input-group-text bg-success"><i class="fa-solid fa-money-bills"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">N° Seguro Social / Particular:</label>

                      <div class="input-group">
                        <input type="text"
                          name=""
                          className="form-control"
                          /*value={formData.SERE_chAPEMAT}
                    onChange={handleChange}*/
                          placeholder="Ingrese el N° de Seguro"
                        />
                        <span class="input-group-text bg-danger"><i class="fa-solid fa-shield-heart" style={{ color: 'white' }}></i></span>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Brevete:</label>
                      <div class="input-group">
                        <input type="text"
                          name=""
                          className="form-control"
                          /*value={formData.AFIL_P_inID}
                          onChange={handleChange}*/
                          placeholder="Ingrese el N° de Brevéte"
                        />
                        <span class="input-group-text bg-warning"><i class="fa-regular fa-id-card"></i></span>
                      </div>

                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">

                    <div className="form-group">
                      <label htmlFor="">Fecha Venc.:</label>
                      <div class="input-group">
                        <input type="text"
                          name=""
                          className="form-control"
                          /*value={formData.AFIL_P_inID}
                          onChange={handleChange}*/
                          placeholder="mm/aaaa"
                        />
                        <span class="input-group-text bg-warning"><i class="fa-regular fa-calendar-xmark"></i></span>
                      </div>

                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">N° Licencia:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chLICCON"
                          className="form-control"
                          value={formData.SERE_chLICCON}
                          onChange={handleChange}
                          placeholder="Ingrese su N° de licencia"
                        />
                        <span class="input-group-text bg-warning"><i class="fa-regular fa-id-card"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Talla de Polo:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chTALPOL"
                          className="form-control"
                          value={formData.SERE_chTALPOL}
                          onChange={handleChange}
                          placeholder="Ingrese su Talla de Polo"
                        />
                        <span class="input-group-text bg-dark"><i class="fa-solid fa-shirt" style={{ color: 'white' }}></i></span>
                      </div>

                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Talla de Pantalón:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chTALPAN"
                          className="form-control"
                          value={formData.SERE_chTALPAN}
                          onChange={handleChange}
                          placeholder="Ingrese su Talla de Pantalón"
                        />
                        <span class="input-group-text bg-dark"><i class="fa-solid fa-arrows-up-down" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Talla de Zapatos</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chTALCAL"
                          className="form-control"
                          value={formData.SERE_chTALCAL}
                          onChange={handleChange}
                          placeholder="Ingrese su Talla de Zapatos"
                        />
                        <span class="input-group-text bg-dark"><i class="fa-solid fa-shoe-prints" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Talla:</label>

                      <div class="input-group">
                        <input type="text"
                          name="SERE_reTALLA"
                          className="form-control"
                          value={formData.SERE_reTALLA}
                          onChange={handleChange}
                          placeholder="Ingrese su Talla"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-up-down" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Peso:</label>

                      <div class="input-group">
                        <input type="text"
                          name="SERE_rePESO"
                          className="form-control"
                          value={formData.SERE_rePESO}
                          onChange={handleChange}
                          placeholder="Ingrese su Peso"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-weight-scale" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Tipo de Sangre:</label>
                      <div class="input-group">
                        <input type="text"
                          name="SERE_chTIPSAN"
                          className="form-control"
                          value={formData.SERE_chTIPSAN}
                          onChange={handleChange}
                          placeholder="Ingrese su Tipo de Sangre"
                        />
                        <span class="input-group-text bg-secondary"><i class="fa-solid fa-hand-holding-droplet" style={{ color: 'white' }}></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </Slider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button
          variant={initialData ? "warning" : "primary"}
          onClick={handleSubmit}
        >
          {initialData ? "Actualizar" : "Registrar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
{/*<div>
            <div className="row">
              <h2 style={{ textAlign: "center" }}>Datos de Contrato y Pago</h2>
              <div
                className="col-xs-12 col-sm-12 col-md-12"
                style={{ padding: "15px" }}
              >
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Salario Tratado:</label>
                      <div class="input-group">
                        <input type="text" 
                        name="SERE_foSUE"
                        className="form-control"
                        value={formData.SERE_foSUE}
                        onChange={handleChange}
                        placeholder="Ingrese Salario"
                        />
                        <span class="input-group-text bg-success"><i class="fa-solid fa-money-bill" style={{color:'white'}}></i></span>
                      </div> 
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Fecha de Ingreso:</label>
                      <input
                        type="date"
                        name="SERE_chFECALT"
                        className="form-control"
                        //value={formatDateForDisplay(formData.SERE_chFECALT)} 
                        //onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Fecha de Salida:</label>
                      <input
                        type="date"
                        name="SERE_chFECBAJ"
                        className="form-control"
                        //value={formatDateForDisplay(formData.SERE_chFECBAJ)}
                        //onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                      <div className="form-group">
                        <label htmlFor="">CTA Interbancaria:</label>
                        <div class="input-group">
                          <input type="text" 
                          name="SERE_chNCCI"
                          className="form-control"
                          value={formData.SERE_chNCCI}
                          onChange={handleChange}
                          placeholder="Ingrese su N° de CCI"
                          />
                          <span class="input-group-text bg-success"><i class="fa-solid fa-money-bill-transfer" style={{color:'white'}}></i></span>
                        </div>
                      </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">N° Cuenta Bancaria:</label>
                      <div class="input-group">
                        <input type="text" 
                        name="SERE_chCTABAN"
                        className="form-control"
                        value={formData.SERE_chCTABAN}
                        onChange={handleChange}
                        placeholder="Ingrese su N° de Cuenta Bancaria"
                        />
                        <span class="input-group-text bg-success"><i class="fa-solid fa-piggy-bank" style={{color:'white'}}></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Banco:</label>
                      <div class="input-group">
                        <select
                          className="form-select"
                          name="SERE_chNOMBAN"
                          value={formData.SERE_chNOMBAN}
                          onChange={handleChange}
                        >
                          <option value="Scotiabank">Scotiabank</option>
                          <option value="Interbank">Interbank</option>
                          <option value="BBVA">BBVA</option>
                          <option value="BCP">BCP</option>
                        </select>
                          <span class="input-group-text bg-success"><i class="fa-solid fa-building-columns" style={{color:'white'}}></i></span>
                        </div>
                    </div>
                  </div>
                </div>                
                <div className="row" style={{ "margin-top": "10px " }}>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="">Tipo de cuenta:</label>
                        <select
                        className="form-select"
                        name="SERE_chTIPCTA"
                        value={formData.SERE_chTIPCTA}
                        onChange={handleChange}
                      >
                        <option value="Cuenta de Ahorros">Cuenta de Ahorros</option>
                        <option value="Cuenta Sueldo">Cuenta Sueldo</option>
                      </select>
                      </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">*N° Cuenta Banco de la Nación:</label>
                      <input
                        type="text"
                        name="SERE_chCTAOPC"
                        className="form-control"
                        value={formData.SERE_chCTAOPC}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="alert alert-info" role="alert" style={{width:'95%',margin:'auto'}}>
                *Ingresar Cuenta de Banco de la Nación en caso de estar sujeto a detracción 
              </div>
            </div>
          </div>
*/}
export default FormUsuario;
