export const PublicRoutes ={
    AUTH: '/' ,
    LOGIN: 'login' 
}
export const RoutesPrivate={
    DASHBOARD: '/principal',
    PERSONALSEGURIDAD: '/personalseguridad',
    MANTENIMIENTOSEGURIDAD: '/MantenimientoSeguridad',
    GESTIONPAGOS: '/gestion-pagos',
    PERSONALRONDA: '/PersonalRonda',
}