// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
    transition: transform 0.5s ease-in-out;
}
.row.d-flex {
    transform: translateX(0%);
}
.row.d-none {
    transform: translateX(-100%);
    display: none;
}
.switch-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
.switch-button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/asistencia.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;IAC5B,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".row {\r\n    transition: transform 0.5s ease-in-out;\r\n}\r\n.row.d-flex {\r\n    transform: translateX(0%);\r\n}\r\n.row.d-none {\r\n    transform: translateX(-100%);\r\n    display: none;\r\n}\r\n.switch-button {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    background-color: #007bff;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n}\r\n.switch-button:hover {\r\n    background-color: #0056b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
