// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.label {
    font-family: Arial, sans-serif;
    font-size: 0.70rem;
    font-weight: bold;
    color: #333;
  }
  
  .input {
    font-size: 0.75rem;
    font-family: Arial, sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/CSS/formulario.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,8BAA8B;EAChC","sourcesContent":["/* styles.css */\r\n.label {\r\n    font-family: Arial, sans-serif;\r\n    font-size: 0.70rem;\r\n    font-weight: bold;\r\n    color: #333;\r\n  }\r\n  \r\n  .input {\r\n    font-size: 0.75rem;\r\n    font-family: Arial, sans-serif;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
