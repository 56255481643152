import React, { useEffect, useState } from 'react';
import { Button, Modal, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/App.css';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
import API_ASISTENCIA from '../../componentes/config/apisAsistencia_Variables';
import ModalRegistroCapacidad from './modals/addCapacidad';
import ModalActualizarCapacidad from './modals/editCapacidad';
import credenciales from '../../componentes/config/credenciales';

function MantenimientoSeguridad({ fecha }) {
  const [selectedDate, setSelectedDate] = useState(fecha || new Date().toISOString().split('T')[0]);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = (refresh = false) => {
    setShowModal(false);
    if (refresh) fetchReportData(); // Actualiza la tabla después de un POST.
  };

  const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => {setShowModal2(false);};

  const fetchReportData = async () => {
    if (!selectedDate) return;

    setIsLoading(true);
    try {
      const url = API_ASISTENCIA.capacidadAsisList(selectedDate);
      const username = credenciales.usuario;
      const password = credenciales.contrasena;
      const encodedCredentials = btoa(`${username}:${password}`);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos.');
      }

      const result = await response.json();
      if (result && result.data) {
        setReportData(result.data);
        setFilteredData(result.data); // Inicializamos los datos filtrados
      } else {
        setReportData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setReportData([]);
      setFilteredData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [selectedDate]);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      setFilteredData(
        reportData.filter((row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(lowercasedQuery)
          )
        )
      );
    } else {
      setFilteredData(reportData);
    }
  }, [searchQuery, reportData]);

  const handleLogout = () => {
    Cookies.remove('user');
    window.location.href = '/login';
  };



  const handleDelete = (id) => {
    const username = credenciales.usuario;
    const password = credenciales.contrasena;
    const encodedCredentials = btoa(`${username}:${password}`);
  
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarlo!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_ASISTENCIA.capacidadAsisDelete(id)}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${encodedCredentials}`,
            },
          });
  
          if (response.ok) {
            Swal.fire('Eliminado!', 'El registro ha sido eliminado.', 'success');
            fetchReportData(); // Actualiza los datos después de borrar
          } else {
            Swal.fire('Error', 'Hubo un problema al eliminar el registro.', 'error');
          }
        } catch (error) {
          console.error('Error al eliminar:', error);
          Swal.fire('Error', 'Ocurrió un error al procesar tu solicitud.', 'error');
        }
      }
    });
  };
  
  

  // Paginación
  const startIndex = (currentPage - 1) * pageSize;
  const currentData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <div style={{ width: '100%' }}>

      <header className="d-flex justify-content-center align-items-center py-3">
        <img
          src={require('../../componentes/logo_victoria.png')}
          alt="Logo Victoria"
          className="logoerlan img-fluid"
        />
      </header>

      <nav>
        <a className="atras" href="/principal" style={{ cursor: 'pointer' }}>
          <i className="fa-solid fa-arrow-left"></i> Atras
        </a>
        <a className="salir" onClick={handleLogout} style={{ cursor: 'pointer' }}>
          <i className="fa-solid fa-users-slash"></i> Cerrar Sesión
        </a>
      </nav>

      <div className="container">

        <div className="row mt-2">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
              <button className="btn btn-sm btn-success" onClick={handleShowModal}>
                <i className="fas fa-plus me-2"></i> NUEVO REGISTRO
              </button>
              <ModalRegistroCapacidad showModal={showModal} handleClose={handleCloseModal} />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 d-flex align-items-center">
              <label
                htmlFor="fecha"
                className="me-2 mb-3 fw-bold"  // 'fw-bold' para negrita y 'me-2' y 'mb-3' para márgenes
                style={{ lineHeight: '1.5', fontSize: '0.875rem' }}  // Reducir el tamaño de la fuente
              >
                FILTRAR
              </label>

              <input
                type="date"
                id="fecha"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="form-control  form-control-sm mb-3"
              />
            </div>

        </div>

        <div className="row mb-2 align-items-center justify-content-between">
          <div className="col-md-4 col-sm-6 d-flex align-items-center" style={{ fontSize: '0.875rem' }}>
            <span className="ms-2">Mostrar</span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="form-select form-select-sm"
              style={{ width: 'auto' }}
            >
              {[5, 10, 20, 50, -1].map((size) => (
                <option key={size} value={size}>
                  {size === -1 ? 'Todos' : size}
                </option>
              ))}
            </select>
            <span className="ms-2">registros por página</span>

            {/* Agregar margen entre el select y el botón CSV */}
            <div className="ms-3">
              <CSVLink
                data={filteredData.map(({ CAP_P_inID, ...rest }) => rest)} // Excluir la columna de acciones
                filename={"reporte.csv"}
                className="btn btn-sm btn-primary"
              >
                <i className="fas fa-file-csv me-2"></i>
              </CSVLink>
            </div>
          </div>


          <div className="col-md-3 col-sm-4">
            <InputGroup className="justify-content-end input-group-sm">
              <InputGroup.Text
                id="basic-addon1"
                className="bg-success text-white"
              >
                Buscar
              </InputGroup.Text>
              <FormControl
                placeholder="Buscar..."
                aria-label="Buscar"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
        </div>

        </div>

        <div className="table-responsive overflow-auto text-center">
            {isLoading ? 
            (
                <div className="text-center">
                  <Spinner 
                    animation="border" 
                    role="status" 
                    variant="success" // Cambia el color a success
                    style={{ width: '3rem', height: '3rem' }} // Ajusta el tamaño del spinner
                  >
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                </div>
                  ) : (
                    <table className="table table-sm table-striped table-hover table-bordered shadow-lg dt-responsive nowrap" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th>FECHA</th>
                          <th>FUNCIÓN</th>
                          <th>TOTAL</th>
                          <th>ESTADO</th>
                          <th>ACCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.length === 0 ? (
                          <tr>
                            <td colSpan="5">No hay datos disponibles para esta fecha.</td>
                          </tr>
                        ) : (
                          currentData.map((row) => (
                            <tr key={row.CAP_P_inID}>
                              <td>{new Date(row.CAP_dtFECHOR).toLocaleDateString()}</td>
                              <td>{row.CAP_chFUN}</td>
                              <td>{row.CAP_IN_TOTAL}</td>
                              <td>{row.CAP_CH_EST}</td>
                              <td>
                                <div className="d-flex justify-content-center gap-2">
                                  <Button
                                    variant="warning"
                                    onClick={() => {
                                      handleShowModal2(row.CAP_P_inID);
                                      setSelectedId(row.CAP_P_inID); // Asigna el ID seleccionado
                                    }}
                                    className="btn-sm"
                                    title="Editar"
                                  >
                                    <i className="fa-solid fa-edit"></i>
                                  </Button>

                                  <ModalActualizarCapacidad
                                    showModal2={showModal2}
                                    handleClose2={handleCloseModal2}
                                    selectedId={selectedId} // Pasa el ID seleccionado
                                  />

                                  <Button
                                    variant="danger"
                                    onClick={() => handleDelete(row.CAP_P_inID)}
                                    className="btn-sm"
                                    title="Eliminar"
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
              )}
        </div>

          {/* Pie de la tabla con paginación */}
          <div className="d-flex justify-content-between mt-3">
              {/* Información de la página a la izquierda */}
              <div className="d-flex align-items-center" style={{ fontSize: '0.875rem' }}>            
                  <span className="ms-2">Página {currentPage} de {Math.ceil(filteredData.length / pageSize)} (Total: {filteredData.length} registros)</span>               
              </div>
        
              {/* Botones de paginación a la derecha */}
              <div className="d-flex align-items-center">
                <Button 
                  variant="primary" 
                  size="sm" 
                  className="mx-1"
                  onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                >
                  <i className="fas fa-chevron-left"></i>
                </Button>
                <Button 
                  variant="primary" 
                  size="sm" 
                  className="mx-1"
                  onClick={() => setCurrentPage(currentPage < Math.ceil(filteredData.length / pageSize) ? currentPage + 1 : currentPage)}
                >
                  <i className="fas fa-chevron-right"></i>
                </Button>
              </div>

          </div>
      </div>
  </div>
  );
}

export default MantenimientoSeguridad;
