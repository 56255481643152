import { useState, useEffect, useRef } from 'react';
import { Button, Modal, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import API_ASISTENCIA from '../../../../componentes/config/apisAsistencia_Variables';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { CSVLink } from "react-csv";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import '../../../CSS/asistencia.css';


const ModalReporte = ({ showModal, handleClose, fecha }) => {
  const [selectedDate, setSelectedDate] = useState(fecha || new Date().toISOString().split('T')[0]);
  const [horaInicio, setHoraInicio] = useState('');
  const [horaFin, setHoraFin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]);
  const [tableModalShow, setTableModalShow] = useState(false);
  const [pageSize, setPageSize] = useState(5); // Tamaño de página por defecto
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeRow, setActiveRow] = useState(1);
  const [percentageTotalAsistencia, setPercentageTotalAsistencia] = useState(0);
  const [progressBarColor, setProgressBarColor] = useState('bg-danger');


  // Filtrar datos basados en la búsqueda
  const filteredData = reportData?.asistencias?.filter((asistencia) => {
    // Convertir todos los campos a minúsculas para realizar la búsqueda de manera insensible a mayúsculas
    const values = Object.values(asistencia).map(value => value ? value.toString().toLowerCase() : '');
    const searchQueryLower = searchQuery.toLowerCase();

    // Verificar si el searchQuery aparece en algún campo de la asistencia
    return values.some(value => value.includes(searchQueryLower));
  }) || [];



  const paginatedData = pageSize === -1
    ? filteredData // Si el tamaño de página es -1, mostramos todos los datos
    : filteredData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );


  // Calcular total de páginas
  const totalPages = pageSize === -1
    ? 1 // Si estamos mostrando todos los datos, solo hay una página
    : Math.ceil(filteredData.length / pageSize);


  const handleSlide = () => {
    setActiveRow((prevRow) => (prevRow === 1 ? 2 : 1));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleHoraInicioChange = (e) => {
    setHoraInicio(e.target.value);
  };

  const handleHoraFinChange = (e) => {
    setHoraFin(e.target.value);
  };

  const calculateProgressColor = (percentage) => {
    if (percentage >= 90) return 'bg-success';
    if (percentage >= 75) return 'bg-primary';
    return 'bg-danger';
  };

  const fetchReportData = async () => {
    if (!selectedDate) return;

    setIsLoading(true);
    setReportData(null);
    try {
      const url = API_ASISTENCIA.totalAsistenciasPorFecha(selectedDate, horaInicio, horaFin);
      const username = 'ACASUSOLF65';
      const password = '47476065';
      const encodedCredentials = btoa(`${username}:${password}`);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos.');
      }

      const data = await response.json();
      setReportData(data);

      // Calcula el porcentaje y actualiza los estados relacionados
      const total = data?.total || 0;
      const total2 = data?.total2 || 0;
      const percentage = total2 > 0 ? ((total / total2) * 100).toFixed(1) : 0;

      setPercentageTotalAsistencia(percentage);
      setProgressBarColor(calculateProgressColor(percentage));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchMonthlyData = async () => {
    if (!selectedDate) return;

    setIsLoading(true);
    try {
      const url = API_ASISTENCIA.totalAsistenciasPorMes(selectedDate);
      const username = 'ACASUSOLF65';
      const password = '47476065';
      const encodedCredentials = btoa(`${username}:${password}`);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos mensuales.');
      }

      const data = await response.json();
      const preparedData = prepareMonthlyData(data.totales || []);
      setMonthlyData(preparedData);
    } catch (error) {
      console.error('Error:', error);
      setMonthlyData([]);
    } finally {
      setIsLoading(false);
    }
  };


  const prepareMonthlyData = (data) => {
    const year = new Date(selectedDate).getFullYear();
    const month = new Date(selectedDate).getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const allDays = Array.from({ length: daysInMonth }, (_, i) => ({
      fecha: `${year}-${String(month + 1).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
      suma_total: 0,
    }));

    data.forEach(item => {
      const date = item.fecha.split('-').slice(0, 3).join('-');
      const dayData = allDays.find(d => d.fecha === date);
      if (dayData) {
        dayData.suma_total = item.suma_total;
      }
    });

    return allDays;
  };

  useEffect(() => {
    fetchReportData();
    fetchMonthlyData();
  }, [selectedDate, horaInicio, horaFin]);


  const chartOptions = reportData
    ? (() => {
      const totalAsistencias = reportData.total || 0;
      const funcionesCount = reportData.funciones_count || {};
      const chartData = Object.entries(funcionesCount).map(([funcion, { asistencias_count }]) => {
        return {
          name: funcion,
          y: (asistencias_count / totalAsistencias) * 100,
          asistencias_count,
        };
      });

      return {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },
        title: {
          text: 'Distribución de Asistencias por Función',
        },
        subtitle: {
          text: `Fecha seleccionada: ${selectedDate}`,
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
            dataLabels: {
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series: [{
          name: 'Asistencias',
          data: chartData,
        }],
      };
    })()
    : {};

  const monthlyChartOptions = {
    chart: {
      type: 'column',
      events: {
        load: function () {
          // Añadir la línea de dispersión al gráfico
          const series = this.series;
          const lineSeries = this.addSeries({
            type: 'line',
            name: 'Promedio',
            data: monthlyData.map(item => [item.fecha.split('-')[2], item.suma_total]),
            marker: {
              enabled: true,
            },
            tooltip: {
              pointFormat: 'Promedio: <b>{point.y:.1f}</b>',
            },
          });
        },
      },
    },
    title: {
      text: 'Asistencias por Día del Mes',
    },
    subtitle: {
      text: `Mes: ${new Date(selectedDate).toLocaleString('default', { month: 'long' })}`, // Nombre del mes seleccionado
    },
    xAxis: {
      type: 'category',
      labels: {
        autoRotation: [-45, -90],
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total de Asistencias',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: 'Asistencias: <b>{point.y:.1f}</b>',
    },
    series: [{
      name: 'Asistencias',
      colors: [
        '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b', '#00f194'
      ],
      colorByPoint: true,
      groupPadding: 0,
      data: monthlyData.map(item => [item.fecha.split('-')[2], item.suma_total]), // Solo el día
      dataLabels: {
        enabled: true,
        rotation: -90,
        color: '#FFFFFF',
        inside: true,
        verticalAlign: 'top',
        format: '{point.y:.1f}', // Un decimal
        y: 10, // 10 píxeles hacia abajo desde la parte superior
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif',
        },
      },
    }],
  };



  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton className="bg-danger text-white">
          <Modal.Title>TOTAL ASISTENCIA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-3 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="dateFilter" className="form-label mb-0 me-3">
                  Fecha:
                </label>
                <input
                  type="date"
                  id="dateFilter"
                  className="form-control form-control-sm"
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ maxWidth: '160px' }}
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="horaInicio" className="form-label mb-0 me-3">
                  Hora Inicio:
                </label>
                <input
                  type="time"
                  id="horaInicio"
                  className="form-control form-control-sm"
                  value={horaInicio}
                  onChange={handleHoraInicioChange}
                  style={{ maxWidth: '150px' }}
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="horaFin" className="form-label mb-0 me-3">
                  Hora Fin:
                </label>
                <input
                  type="time"
                  id="horaFin"
                  className="form-control form-control-sm"
                  value={horaFin}
                  onChange={handleHoraFinChange}
                  style={{ maxWidth: '150px' }}
                />
              </div>
            </div>
          </div>

          <div id="row-1" className={`row transition-row ${activeRow === 1 ? "d-flex" : "d-none"}`}>
            <div className="col-12 col-md-4">
              {isLoading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="danger" />
                  <p>Cargando datos...</p>
                </div>
              ) : reportData ? (
                <div>

                  <div className="row mt-2 mb-3 text-center m-2">
                    <div className="col-12 col-md-6  bg-info p-2" style={{ fontSize: '0.65rem' }}>
                      <h6>ASISTENCIAS: {reportData.total}</h6>
                    </div>
                    <div className="col-12 col-md-6 bg-success p-2 text-white" style={{ fontSize: '0.65rem' }}>
                      <h6>CAPACIDAD: {reportData.total2}</h6>
                    </div>
                  </div>

                  <div className="table-responsive overflow-auto text-center">
                    <table className="table table-sm table-striped table-hover table-bordered shadow-lg dt-responsive nowrap" style={{ width: '100%' }}>
                      <thead style={{ fontSize: "0.75rem", textAlign: "center" }}>
                        <tr>
                          <th>FUNCIÓN</th>
                          <th>ASIS</th>
                          <th>CAP</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "0.75rem", textAlign: "center" }}>
                        {Object.entries(reportData?.funciones_count || {}).map(
                          ([funcion, { asistencias_count, capacidad_total }]) => {
                            const porcentaje = capacidad_total > 0
                              ? ((asistencias_count / capacidad_total) * 100).toFixed(1)
                              : 0;

                            return (
                              <tr key={funcion}>
                                <td style={{ fontWeight: "bold" }}>{funcion}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm bg-info text-dark text-center font-weight-bold fs-6"
                                    value={asistencias_count}
                                    readOnly
                                    style={{ width: "60px", textAlign: "center" }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm bg-success text-white text-center font-weight-bold fs-6"
                                    value={capacidad_total}
                                    readOnly
                                    style={{ width: "60px", textAlign: "center" }}
                                  />
                                </td>
                                <td className="text-center font-weight-bold fs-6">
                                  {porcentaje} %
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>

                  <Button
                    variant="warning"
                    onClick={() => setTableModalShow(true)}
                    className="btn-sm font-weight-bold w-100 text-center" // Clase de texto centrado
                  >
                    <i className="fas fa-eye mr-2"></i> {/* Ícono de vista */}
                    DETALLE
                  </Button>

                </div>
              ) : (
                <p>No se encontraron datos.</p>
              )}
            </div>



            <div className="col-12 col-md-8">

              <div className="row">


                <div className="col-12">
                  {/* Título encima de la barra */}
                  <div className="mb-2 text-bold">
                    <h6>PORCENTAJE ACTIVO DE PERSONAL EN CAMPO</h6>
                  </div>

                  {/* Barra de progreso */}
                  <div className="progress" style={{ height: "20px" }}> {/* Altura personalizada */}
                    <div
                      className={`progress-bar progress-bar-striped ${progressBarColor}`}
                      role="progressbar"
                      style={{ width: `${percentageTotalAsistencia}%` }}
                      aria-valuenow={percentageTotalAsistencia}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {percentageTotalAsistencia}%
                    </div>
                  </div>
                </div>


                <div className="col-12">
                  {reportData && (
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                  )}
                </div>
              </div>

            </div>
          </div>

          <div id="row-2" className={`row transition-row ${activeRow === 2 ? "d-flex" : "d-none"}`}>

            <div className="col-12">
              {monthlyData.length > 0 && (
                <HighchartsReact highcharts={Highcharts} options={monthlyChartOptions} />
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col d-flex justify-content-center align-items-center">
              <h6 className="text-bold mb-0 me-2">CAMBIAR REPORTE GRÁFICO</h6>
              <Button variant="outline-primary" onClick={handleSlide}>
                {activeRow === 1 ? '➡️' : '⬅️'}
              </Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>


      {/* Modal de la tabla con paginación */}
      <Modal show={tableModalShow} onHide={() => setTableModalShow(false)} size="xl" centered>
        <Modal.Header closeButton className="bg-warning text-center">
          <Modal.Title className="w-100">DETALLE DE ASISTENCIA</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-2 align-items-center justify-content-between">
            <div className="col-md-4 col-sm-6 d-flex align-items-center" style={{ fontSize: "0.875rem" }}>
              <span className="ms-2">Mostrar</span>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-select form-select-sm"
                style={{ width: "auto" }}
              >
                {[5, 10, 20, 50, -1].map((size) => (
                  <option key={size} value={size}>
                    {size === -1 ? "Todos" : size}
                  </option>
                ))}
              </select>

              <span className="ms-2">registros por página</span>
              <div className="ms-3">
                <CSVLink
                  data={filteredData.map(({ CAP_P_inID, ...rest }) => rest)} // Excluir la columna de acciones
                  filename={"reporte.csv"}
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-file-csv me-2"></i>
                </CSVLink>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <InputGroup className="justify-content-end input-group-sm">
                <InputGroup.Text id="basic-addon1" className="bg-success text-white">
                  Buscar
                </InputGroup.Text>
                <FormControl
                  placeholder="Buscar..."
                  aria-label="Buscar"
                  aria-describedby="basic-addon1"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>

          {/* Tabla principal */}
          <div className="table-responsive overflow-auto text-center">
            <table className="table table-sm table-striped table-hover table-bordered shadow-lg dt-responsive nowrap" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>NOMBRE</th>
                  <th>FUNCIÓN</th>
                  <th>HORA</th>
                  <th>REGISTRO</th>
                  <th>SEXO</th>
                  <th>MODALIDAD</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((asistencia, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{asistencia.nombre_completo} | {asistencia.SERE_P_chDNI}</td>
                      <td>{asistencia.SERE_chFUNCION || "Sin asignar"}</td>
                      <td>{asistencia.ASIS_chHOR}</td>
                      <td>{asistencia.ASIS_chTIPREG}</td>
                      <td>{asistencia.sexo}</td>
                      <td>{asistencia.modalidad}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No hay datos disponibles</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Paginación */}
          <div className="d-flex justify-content-between mt-3">
            <div className="d-flex align-items-center" style={{ fontSize: "0.875rem" }}>
              <span className="ms-2">
                Página {currentPage} de {totalPages} (Total: {filteredData.length} registros)
              </span>
            </div>
            <div className="d-flex align-items-center">
              <Button
                variant="primary"
                size="sm"
                className="mx-1"
                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                disabled={currentPage === 1}
              >
                <i className="fas fa-chevron-left"></i>
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="mx-1"
                onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
                disabled={currentPage === totalPages}
              >
                <i className="fas fa-chevron-right"></i>
              </Button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setTableModalShow(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalReporte;
